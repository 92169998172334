<template>
    <div class="w">
        <el-row :gutter="20">
            <el-col :span="6" >
                <!-- <el-tabs tab-position="left" >
                    <el-tab-pane label="用户管理">用户管理</el-tab-pane>
                    <el-tab-pane label="配置管理">配置管理</el-tab-pane>
                    <el-tab-pane label="角色管理">角色管理</el-tab-pane>
                    <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>
                </el-tabs> -->
              <div class="func">
                <div class="img">
                    <img  src="http://183.6.57.249:8925/img/bg1.jpg" alt="">
                    <div class="user-avatar">
                        <img style="width: 100%;height: 100%;" :src="content.avatar" alt="">
                    </div>
                </div>
                <ul>
                    <li @click="logout"> <i class="el-icon-upload2"></i>退出登录</li>
                </ul>
              </div>
                
            </el-col>
            <el-col :span="18" >
                <el-card shadow="never" class="box-card">
                <div slot="header" class="clearfix">
                    <span class="title">最近阅读</span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                </div>
                <div class="card-content">
                    <div v-for="item in stepList" :key="item.bookId" class="text item" @click="$router.push({path:'/detail/'+item.bookId})">
                        <div>
                            <img style="width: 100%;height: 100%;" :src="item.book.titleImg" alt="">
                        </div>
                        <p class="es-1">{{item.book.title}}</p>
                    </div>
                </div>
                
                </el-card>
                <el-card shadow="never" class="box-card">
                <div slot="header" class="clearfix">
                    <span class="title">我的收藏</span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                </div>
                <div class="card-content">
                    <div v-for="item in collectList" :key="item.bookId" class="text item" @click="$router.push({path:'/detail/'+item.bookId})">
                        <div>
                            <img style="width: 100%;height: 100%;" :src="item.titleImg" alt="">
                        </div>
                        <p class="es-1">{{item.title}}</p>
                    </div>
                </div>
                
                </el-card>
            </el-col>
            
        </el-row>
        
    </div>
</template>

<script>
import {getStep,userCollect } from "@/api";
    export default {
        name:'user',
        data() {
            return {
                stepList:[],
                collectList:[],
                content:{}
            }
        },
        created () {
            this.content=this.Storage.get('userInfo')
            // if(this.Storage.get('token')){
                this.getStepList()
                this.getCollectList()
            // }
          
        },  
        methods: {
            logout() {
                this.$confirm('确定退出登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.Storage.clear()
                location.href = "/";
              
            })
            },
            getCollectList(){
                userCollect().then(res=>{
                    // console.log(res);
                    if(res.code === 200){
                        this.collectList=res.rows
                    }
                })
            },
          getStepList(){
            getStep().then(res=>{
                // console.log(res);
                if(res.code === 200){
                    this.stepList=res.rows
                }
            })
          }
        },
        
    }
</script>

<style lang="scss" scoped>
.func {
    // background-color: #fff;
    border: 1px solid #ccc;
    width: 100%;
    .img {
        position: relative;
        width: 100%;
        height: 200px;
        img {
            width: 100%;
            height: 100%;
        }
        .user-avatar {
            background-color: #fff;
            border-radius: 50%;
            overflow: hidden;
            border: 5px dashed #fff;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    ul {
        li {
            cursor: pointer;
            background-color: #F56C6C;
            color: #fff;
            border-bottom: 1px solid #ccc;
            line-height: 80px;
            font-size: 22px;
            text-align: center;
            &:hover {
                background-color: red;
            }
        }
        :last-child {
            border-bottom:none;
        }
    }
}


 .text {
    font-size: 14px;
  }

  

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    // width: 480px;
    margin-bottom: 30px;
    .title {
        font-size: 20px;
    }
    .card-content {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        
        .item {
            cursor: pointer;
            border-radius: 10px;
            // border: 1px solid #ccc;
            padding: 20px;
            margin-bottom: 18px;
            width: 20%;
            transition: all .3s;
            &:hover {
                box-shadow: 0 0 10px rgba($color: #000000, $alpha: .3);
            }
            p {
                font-size: 20px;
                margin-top: 10px;
                // line-height: 20px;
            }
        }

    }
  }
</style>